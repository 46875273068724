import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { Logger } from '@vue-storefront/core/lib/logger'
import config from 'config'

Vue.use(VueI18n)

const loadedLanguages: string[] = []

const defaultMessages = {
  [config.i18n.defaultLocale]: require(`./resource/i18n/${config.i18n.defaultLocale}.json`).default
}

const i18n = new VueI18n({
  locale: config.i18n.defaultLocale,
  fallbackLocale: config.i18n.defaultLocale,
  messages: defaultMessages,
  silentTranslationWarn: true
})

function setI18nLanguage (lang: string): string {
  i18n.locale = lang
  document.querySelector('html')?.setAttribute('lang', lang)
  return lang
}

export async function loadLanguageAsync (lang: string): Promise<string> {
  try {
    if (loadedLanguages.includes(lang)) {
      return setI18nLanguage(lang)
    }

    const messages = await import(/* webpackChunkName: "lang-[request]" */ `./resource/i18n/${lang}.json`)

    i18n.setLocaleMessage(lang, messages.default)
    loadedLanguages.push(lang)

    return setI18nLanguage(lang)
  } catch (e) {
    Logger.debug(`Unable to load language: ${lang}`)()
    return lang
  }
}

export { i18n }
export default i18n
